import PropTypes from 'prop-types'
import {
  Button,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import React, { useState } from 'react'
import DataTable from './data-table'
import { makeStyles } from '@material-ui/styles'
import { frequencyStr } from '../../services/frequency'
import { saveMultipleEvents } from '../../services/admin'
import { Alert } from '@material-ui/lab'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(theme => ({
  controlPanel: {
    marginBottom: 20,
  },
  formControl: {
    minWidth: 130,
  },
}))
const Dashboard = ({ plants }) => {
  const classes = useStyles()
  const [action, setAction] = useState('water')
  const [message, setMessage] = useState('')
  const [selected, setSelected] = useState([])
  const [messageOpen, setMessageOpen] = useState(false)
  const handleSelectionChange = selected => {
    setSelected(selected.rows.map(r => r.Handle))
  }
  const applyActionHandler = () => {
    saveMultipleEvents({ action, actors: selected }).then(data => {
      console.log({ data })
      setMessageOpen(true)
      setMessage(
        `${data.length} plant${
          data.length > 1 ? 's were' : ' was'
        } ${action}ed!`
      )
    })
  }
  const columns = [
    { field: 'Name', headerName: 'Name', width: 200 },
    { field: 'Handle', headerName: 'Handle', width: 130 },
    {
      field: 'DoB',
      headerName: 'DoB',
      width: 130,
      type: 'date',
    },
    {
      field: 'Family',
      headerName: 'Family',
      width: 160,
    },
    {
      field: 'Genus',
      headerName: 'Genus',
      width: 160,
    },
    {
      field: 'Species',
      headerName: 'Species',
      width: 200,
    },
    {
      field: 'WateringFrequency',
      headerName: 'WateringFrequency',
      width: 200,
      type: 'number',
      valueGetter: params =>
        `${frequencyStr(params.getValue('WateringFrequency'))}`,
    },
  ]

  return (
    <div>
      <Grid
        container
        spacing={3}
        className={classes.controlPanel}
        justify="flex-end"
        alignItems="center"
      >
        <Grid item xs>
          <Collapse in={messageOpen}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setMessageOpen(false)
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {message}
            </Alert>
          </Collapse>
        </Grid>
        <Grid item>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Actions</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={action}
              autoWidth
              onChange={e => setAction(e.target.value)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={'water'}>Water</MenuItem>
              <MenuItem value={'fertilize'}>Fertilize</MenuItem>
              <MenuItem value={'mist'}>Mist</MenuItem>
              <MenuItem value={'rotate'}>Rotate</MenuItem>
              <MenuItem value={'repot'}>Repot</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {action && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={applyActionHandler}
            >
              {selected.length ? (
                <>
                  {action} {selected.length} plant
                  {selected.length > 1 ? 's' : ''}
                </>
              ) : (
                `Select some plants`
              )}
            </Button>
          </Grid>
        )}
      </Grid>

      <DataTable
        rows={plants}
        columns={columns}
        pageSize={10}
        height={650}
        checkboxSelection
        onSelectionChange={handleSelectionChange}
      ></DataTable>
    </div>
  )
}

Dashboard.propTypes = {
  plants: PropTypes.array,
}

export default Dashboard
