import PropTypes from 'prop-types'
import React from 'react'
import { navigate } from 'gatsby'
import { isLoggedIn } from '../services/auth'

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (
    typeof window !== 'undefined' &&
    !isLoggedIn() &&
    location.pathname !== `/login`
  ) {
    navigate('/login')
    return null
  }

  return <Component {...rest} />
}

PrivateRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

export default PrivateRoute
