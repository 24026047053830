import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Button, Collapse, Grid, IconButton } from '@material-ui/core'
import DataTable from './data-table'
import { makeStyles } from '@material-ui/styles'

import * as timeago from 'timeago.js'
import { deleteMultipleEvents } from '../../services/admin'
import { Alert } from '@material-ui/lab'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(theme => ({
  controlPanel: {
    marginBottom: 20,
  },
  formControl: {
    minWidth: 130,
  },
}))
const EventsTable = ({ events }) => {
  const classes = useStyles()
  const [message, setMessage] = useState('')
  const [selected, setSelected] = useState([])
  const [messageOpen, setMessageOpen] = useState(false)
  const handleSelectionChange = selected => {
    setSelected(selected.rows.map(r => r.id))
  }
  const applyActionHandler = () => {
    deleteMultipleEvents(selected).then(data => {
      console.log({ data })
      setMessageOpen(true)
      setMessage(data.message)
    })
  }
  const columns = [
    {
      field: 'createdAt',
      headerName: 'Date',
      width: 150,
      type: 'date',
      valueGetter: params => `${timeago.format(params.getValue('createdAt'))}`,
    },
    {
      field: 'actor',
      headerName: 'Plant',
      width: 160,
    },
    {
      field: 'eventType',
      headerName: 'Event Type',
      width: 120,
    },
    {
      field: 'text',
      headerName: 'Text',
      width: 160,
    },
  ]

  return (
    <div>
      <Grid
        container
        spacing={3}
        className={classes.controlPanel}
        justify="flex-end"
        alignItems="center"
      >
        <Grid item xs>
          <Collapse in={messageOpen}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setMessageOpen(false)
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {message}
            </Alert>
          </Collapse>
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={applyActionHandler}
          >
            {selected.length ? (
              <>
                Delete {selected.length} event
                {selected.length > 1 ? 's' : ''}
              </>
            ) : (
              `Select some events`
            )}
          </Button>
        </Grid>
      </Grid>

      <DataTable
        rows={events}
        columns={columns}
        pageSize={10}
        height={650}
        checkboxSelection
        onSelectionChange={handleSelectionChange}
      ></DataTable>
    </div>
  )
}

EventsTable.propTypes = {
  events: PropTypes.array,
}

export default EventsTable
