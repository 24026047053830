import React from 'react'
import PropTypes from 'prop-types'
import EventsTable from './events-table'
import { useAdminEventApi } from '../../services/admin'

const ManageEvents = props => {
  return (
    <div>
      {useAdminEventApi(({ events }) => (
        <EventsTable events={events.list().data.map(e => e.data)} />
      ))}
    </div>
  )
}

ManageEvents.propTypes = {
  events: PropTypes.array,
}

export default ManageEvents
