import PropTypes from 'prop-types'
import * as React from 'react'
import { DataGrid } from '@material-ui/data-grid'

export default function DataTable(props) {
  return (
    <div style={{ height: props.height, width: '100%' }}>
      <DataGrid {...props} />
    </div>
  )
}

DataTable.propTypes = {
  checkboxSelection: PropTypes.bool,
  columns: PropTypes.array,
  height: PropTypes.number,
  pageSize: PropTypes.number,
  rows: PropTypes.array,
}
