import React, { useState } from 'react'
import { Router } from '@reach/router'
import Layout from '../components/layout'
import PrivateRoute from '../components/private-route'
import PropTypes from 'prop-types'

import Dashboard from '../components/admin/dashboard'
import { graphql, Link } from 'gatsby'
import { Tab, Tabs, Typography } from '@material-ui/core'
import { frequencyIndex } from '../services/frequency'
import ManageEvents from '../components/admin/manage-events'

const Admin = ({
  data: {
    plantsGraph: { PlantActor: plantActors },
  },
}) => {
  const [tabValue, setTabValue] = useState(0)

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue)
  }

  plantActors = plantActors.map(actor => {
    const species = actor.instance_of[0]
    actor.Species = species.Name
    actor.id = actor._id
    actor.Genus = species.genus[0].Name
    actor.Family = species.genus[0].family[0].Name
    actor.id = actor._id
    actor.WateringFrequency = frequencyIndex(species.WateringFrequency)
    return actor
  })

  return (
    <Layout fullWidth>
      <Typography variant="h4">Leafbook Admin</Typography>
      {/* <Link to="/admin/plants">Plants</Link> */}
      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab
          value={0}
          key="plants"
          label="Plants"
          component={Link}
          to="/admin"
        />
        <Tab
          value={1}
          key="events"
          label="events"
          component={Link}
          to="/admin/events"
        />
      </Tabs>
      <Router>
        <PrivateRoute
          path="/admin"
          component={Dashboard}
          plants={plantActors}
        />
        <PrivateRoute
          path="/admin/events"
          component={ManageEvents}
          plants={plantActors}
        />
      </Router>
    </Layout>
  )
}
export default Admin

export const pageQuery = graphql`
  {
    plantsGraph {
      PlantActor {
        _id
        Name
        Image
        DoB
        Handle
        instance_of {
          Name
          WateringFrequency
          genus {
            Name
            family {
              Name
            }
          }
        }
      }
    }
  }
`
Admin.propTypes = {
  data: PropTypes.object,
}
