const frequencyArray = [
  'Never',
  'Once every month',
  'Twice a month',
  'Every 10 days',
  'Once a week',
  'Twice a week',
  'Every other day',
  'Everyday',
]
export const frequencyIndex = frequencyStr => {
  return frequencyArray.indexOf(frequencyStr)
}
export const frequencyStr = frequencyIndex => {
  if (frequencyIndex > frequencyArray.length - 1)
    console.error('Error, check your frequency')
  return frequencyArray[frequencyIndex]
}
